<template>
  <div class="workShow">
    <h2>赛后教学</h2>
    <div class="span"></div>
    <p class="title">以下视频不作为参赛操作示范，仅为日常操作提供参考</p>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="第二届赛后教学" name="2">
        <div v-show="activeName == 2">
          <div class="box" v-for="(item, index) in videoList2" :key="index">
            <div class="video">
              <video
                :src="item.src"
                controls="controls"
                width="100%"
                id="video2"
                :poster="item.poster"
              ></video>
            </div>
            <div class="text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="第一届赛后教学" name="1">
        <div v-show="activeName == 1">
          <div class="box" v-for="(item, index) in videoList1" :key="index">
            <div class="video">
              <video
                :src="item.src"
                controls="controls"
                width="100%"
                id="video2"
                :poster="item.poster"
              ></video>
            </div>
            <div class="text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="footer">
      <footerCard></footerCard>
    </div>
  </div>
</template>
<script>
import footerCard from "../../components/footerCard.vue";
export default {
  data() {
    return {
      activeName: "2",
      videoList2: [
        {
          src: "https://vod.gyycbj.com/sv/2f85df62-1934c7d1acf/2f85df62-1934c7d1acf.m4v",
          text: "透析机超滤功能检测与校准",
          poster:
            "https://vod.gyycbj.com/image/default/0976081226624CF18A524C14C834E0E0-6-2.png",
        },
        {
          src: "https://vod.gyycbj.com/sv/1e9a1940-1934c7d1ad4/1e9a1940-1934c7d1ad4.m4v",
          text: "CRRT专业《连续性肾脏替代治疗管路安装与预冲准备》",
          poster:
            "https://vod.gyycbj.com/image/default/91B10FC786554266858534E5143F3207-6-2.png",
        },
        {
          src: "https://vod.gyycbj.com/sv/3dca8a8a-1934c7d1b2e/3dca8a8a-1934c7d1b2e.m4v",
          text: "血液透析专业《经自体动静脉内瘘穿刺行血液透析治疗上机的护理操作》",
          poster:
            "https://vod.gyycbj.com/image/default/1FBFA0AB308C4BE3BEB2CA2225B1F78F-6-2.png",
        },
      ],
      videoList1: [
        {
          src: "https://vod.gyycbj.com/sv/38b24631-1889515f633/38b24631-1889515f633.mp4",
          text: "首届大赛赛后教学: 血液透析管路安装与密闭式预冲",
          poster:
            "https://vod.gyycbj.com/image/default/148EE130E31A41638BEAC0807EDAA301-6-2.png",
        },
        {
          src: "https://vod.gyycbj.com/sv/31801709-188d227b94b/31801709-188d227b94b.mp4",
          text: "首届大赛赛后教学: 血液透析治疗的安全保障维护与校对——透析液细菌、内毒素取样及透析液过滤器更换",
          poster:
            "https://vod.gyycbj.com/image/default/53458D49AE6546D88918CF38651E7306-6-2.png",
        },
      ],
    };
  },
  mounted() {
    this.playhandler();
  },
  methods: {
    handleClick() {},
    playhandler() {
      // 获取所有video标签
      var videos = document.getElementsByTagName("video");
      // 监听每个video的play事件
      for (var i = 0; i < videos.length; i++) {
        videos[i].addEventListener("play", function () {
          // 当其他video播放时，暂停它们
          for (var j = 0; j < videos.length; j++) {
            if (videos[j] !== this) {
              videos[j].pause();
            }
          }
        });
      }
    },
  },
  components: {
    footerCard,
  },
};
</script>
<style scoped lang="scss">
.workShow {
  background-image: url("../../assets/image/index-img/bg.webp"),
    url("../../assets/image/index-img/bg2.webp");
  background-position: top, bottom;
  background-repeat: no-repeat, no-repeat;
  padding: 80px 0px;
  @media screen and (max-width: 520px) {
    padding: 0;
  }
  .title {
    text-align: center;
    color: #666;
    margin-bottom: 38px;
    @media screen and (max-width: 520px) {
      margin-bottom: 18px;
      font-size: 14px;
    }
  }
  .box {
    width: 1000px;
    margin: auto;
    margin-top: 20px;
    @media screen and (max-width: 520px) {
      width: 100%;
      padding: 0 20px;
      box-sizing: border-box;
      display: block;
    }
    .video {
      width: 100%;
    }
    .text {
      padding: 15px;
      background: #eee;
      font-size: 25px;
      color: #05258e;
      font-weight: 700;
      @media screen and (max-width: 520px) {
        font-size: 13px;
      }
    }
  }

  ::v-deep .el-tabs__item {
    font-size: 20px !important;
    @media screen and (max-width: 520px) {
      font-size: 14px !important;
      width: 50%;
      text-align: center;
    }
  }
  ::v-deep .el-tabs__nav {
    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }
  ::v-deep .el-tabs__item:hover {
    color: #05258e !important;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #05258e !important;
    font-weight: 600;
  }
  ::v-deep .el-tabs__active-bar {
    background: #05258e;
  }
  ::v-deep .el-image__error {
    background: #fff;
  }
  h2 {
    font-size: 26px;
    text-align: center;
    color: #05258e;
    margin-bottom: 10px;
    @media screen and (max-width: 520px) {
      padding-top: 20px;
    }
  }
  .span {
    display: block;
    margin: 0px auto;
    width: 24px;
    height: 4px;
    background: #05258e;
    margin-bottom: 10px;
    border-radius: 2px;
  }
  .tabs {
    width: 80%;
    margin: auto;
    font-size: 20px;
  }
  .footer {
    margin-top: 200px;
    display: block;
    @media screen and (max-width: 540px) {
      margin-top: 20px;
    }
  }
}
</style>
